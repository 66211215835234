// Generated by Framer (ed8225c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {sOzXKmbv8: {hover: true}};

const cycleOrder = ["sOzXKmbv8", "dZpKCKYwB"];

const serializationHash = "framer-w1H9R"

const variantClassNames = {dZpKCKYwB: "framer-v-21dje9", sOzXKmbv8: "framer-v-t2deil"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "sOzXKmbv8", "Variant 2": "dZpKCKYwB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sOzXKmbv8"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sOzXKmbv8", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-t2deil", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sOzXKmbv8"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"sOzXKmbv8-hover": {"data-framer-name": undefined}, dZpKCKYwB: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><SVG className={"framer-1xjtrer"} data-framer-name={"Icon"} fill={"var(--token-aa2d474b-dc7c-4252-b531-9c1db2d2a329, rgb(255, 255, 255)) /* {\"name\":\"BGW -  100\"} */"} intrinsicHeight={32} intrinsicWidth={32} layoutDependency={layoutDependency} layoutId={"czykvAAq6"} style={{opacity: 0}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" viewBox=\"0 0 256 256\"><path d=\"M216 40h-48a8 8 0 0 0 0 16h16v120.85L111 44.14a8 8 0 0 0-7-4.14H40a8 8 0 0 0 0 16h16v144H40a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16H72V79.15l73 132.71a8 8 0 0 0 7 4.14h40a8 8 0 0 0 8-8V56h16a8 8 0 0 0 0-16ZM77.53 56h21.74l79.2 144h-21.74Z\"/></svg>"} variants={{"sOzXKmbv8-hover": {opacity: 1}, dZpKCKYwB: {opacity: 1}}} withExternalLayout {...addPropertyOverrides({"sOzXKmbv8-hover": {fill: "var(--token-6347c737-e13a-4d21-830c-f754e1347ab9, rgb(0, 0, 0)) /* {\"name\":\"BGW - 800\"} */"}, dZpKCKYwB: {fill: "var(--token-6347c737-e13a-4d21-830c-f754e1347ab9, rgb(0, 0, 0)) /* {\"name\":\"BGW - 800\"} */"}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-w1H9R.framer-1cpwvmc, .framer-w1H9R .framer-1cpwvmc { display: block; }", ".framer-w1H9R.framer-t2deil { cursor: pointer; height: 1px; overflow: visible; position: relative; width: 1px; }", ".framer-w1H9R .framer-1xjtrer { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 1px); left: 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }", ".framer-w1H9R.framer-v-21dje9.framer-t2deil { aspect-ratio: 1 / 1; cursor: unset; height: var(--framer-aspect-ratio-supported, 18px); width: 18px; }", ".framer-w1H9R.framer-v-21dje9 .framer-1xjtrer, .framer-w1H9R.framer-v-t2deil.hover .framer-1xjtrer { height: var(--framer-aspect-ratio-supported, 18px); }", ".framer-w1H9R.framer-v-t2deil.hover.framer-t2deil { height: 18px; width: 18px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 1
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dZpKCKYwB":{"layout":["fixed","fixed"]},"Vy5sbVZPb":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzP3hlKMcE: React.ComponentType<Props> = withCSS(Component, css, "framer-w1H9R") as typeof Component;
export default FramerzP3hlKMcE;

FramerzP3hlKMcE.displayName = "product type icons";

FramerzP3hlKMcE.defaultProps = {height: 1, width: 1};

addPropertyControls(FramerzP3hlKMcE, {variant: {options: ["sOzXKmbv8", "dZpKCKYwB"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerzP3hlKMcE, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})